/* .e-ddl.e-input-group.e-control-wrapper .e-input {
  font-size: 20px;
  font-family: emoji;
  color: #ab3243;
  background: #32a5ab;
  margin-bottom: 1rem;
  padding: 2rem;
} */

.wrapper-div {
  width: 100%;
  margin-bottom: 2rem;
}

.e-accordion .e-acrdn-item.e-select > .e-acrdn-header {
  /* background: #2fa1ff !important; */
  /* justify-content: center; */
  overflow: hidden;
}

.e-acrdn-header-content {
  font-size: 0.85rem !important;
}
