.dashboard-header {
	display: flex;
	margin-top: 1rem;
	margin-inline: 1rem 0;
}

.main-title {
	font-size: 1.5rem;
	font-weight: 800;
}

.grid-container {
	margin: 3rem 1rem;
}
