:root {
	--blue: #0378d5;
}

.e-schedule-dialog .e-location-container,
.e-schedule-dialog .e-all-day-time-zone-row .e-all-day-container,
.e-schedule-dialog .e-all-day-time-zone-row .e-time-zone-container,
.e-schedule-dialog .e-recurrenceeditor .e-editor {
	display: none;
}

.e-schedule-dialog .e-subject-container {
	display: contents;
}

.statustemp.e-inactivecolor {
	background-color: #ffd7cc;
	width: 64px;
}

.statustemp.e-activecolor {
	background-color: #ccffcc;
	width: 57px;
}

.statustxt.e-activecolor {
	color: #00cc00;
}

.statustxt.e-inactivecolor {
	color: #e60000;
}

/* X for reset time value */
.e-start-end-row .e-start,
.e-start-end-row .e-end {
	pointer-events: none;
}


/* button container */
.buttonContainer {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	/* width: 30px; */
	aspect-ratio: 1;
	cursor: pointer;

	border: 1px solid var(--blue);
	border-radius: 50%;
	padding: 5px;
	/* margin-left: 1rem;
	margin-right: 5px; */
}

.buttonContainer i {
	font-size: smaller;
	color: var(--blue);
}

.buttonContainer span {
	font-size: smaller;
	color: #e3165b;
	font-weight: bold;
	position: absolute;
	top: -4px;
	right: -8px;
}

.buttonContainer:hover {
	background-color: var(--blue);
}

.buttonContainer:hover i {
	color: white;
}

/* FLEX CUSTOM: lo splitter non funziona con la griglia */
.flex-container-cal {
	display: flex;
	flex-direction: column;
	margin-left: 20px;
	margin-right: 20px;
}

.flex-row-cal {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 5px;
}

.flex-title-cal {
	font-size: x-large;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-right: 20px;
	margin-right: 20px;
	/*float: left;
	
	
	
	
	*/
}

.flex-button {
	display: flex;
	flex-flow: row;
	justify-content: flex-end;
	position: absolute;
	right: 20px;
}

.flex-data-picker-cal {
	margin-left: auto;
	/* margin-right:20px; */
	/*display: flex;
	flex-flow: row;
	justify-content: flex-end;
	position: absolute;
	right: 20px;*/
}

.flex-button-refresh {
	position: relative;
	left: 12px;
}

.flex-div-left {
	width: 65%;
}

.flex-div-left-100 {
	width: 100%;
}

.flex-div-right {
	width: 35%;
}

.button-margin-5 {
	margin-right: 5px;
}

.button-margin-15 {
	margin-left: 15px;
	margin-right: 15px;
}

.sticky {
	position: sticky;
	top: 0px;
	z-index: 200;
	background-color: white;
	padding-top: 10px;
	padding-bottom: 10px;
}

.roundWhite {
	background-color: white;
	border-radius: 5px;
}

.roundGray {
	background-color: #f5f5f5;
	border-radius: 5px;
}

#body .PrivatePickersYear-yearButton.Mui-selected {
	color: #ffffff;
}

#body .MuiTypography-root.MuiTypography-h5.PrivatePickersMonth-root.Mui-selected {
	color: #ffffff;
}

@media (max-width: 600px) {
	.flex-title {
		font-size: small;
	}
}

/* Landscape/Tablet breakpoint */
@media (max-width: 768px) {
	.e-input-group .e-input {
		font-size: 0.7rem !important;
	}
}

.headerColCalendar {
	min-height: 34px;
	font-size: small;
	font-weight: bold;
	margin: 2px 1px;
	padding-left: 4px;
	padding-top: 6px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.headerRowDayNr {
	height: 145px;
	font-size: small;
	font-weight: bold;
	margin: 2px 1px;
	padding-left: 4px;
	padding-top: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.headerRowRoleDescription {
	height: 31px;
	font-size: small;
	font-weight: bold;
	padding-left: 4px;
	padding-top: 8px;
	margin-left: 5px;
	margin-right: 5px;
}

.calendarXlsContent {
	flex-grow: 1;
	/* margin-right: 20px; */
	min-width: 600px;
}

.calendarBoxStepper {
	max-width: 200px;
	align-self: flex-start;
	margin-top: 25px;
}

.calendarStepContent {
	font-size: 12px;
	font-style: oblique;
}

.calendarStepLabel {
	font-size: 14px;
}

.firstLine {
	display: none !important;
}

@media (max-width: 618px) {
	.calendarXlsContent {
		min-width: 600px;
		/* transform: rotateX(180deg); */
		/*transform made Cambio turno work incorrect when clicked on mobile view */
	}

	#printCal {
		max-width: 1200px;
		overflow-x: scroll;
		/* transform: rotateX(180deg); */
		/*transform made Cambio turno work incorrect when clicked on mobile view */
	}

	#printCal::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 7px;
		height: 7px;
	}

	#printCal::-webkit-scrollbar-thumb {
		background: #2c5282;
		border-radius: 10px;

	}

	.headerLine {
		display: none !important;
	}

	.firstLine {
		display: inherit !important;
	}
}

@media (max-width: 414px) {

	.hideHeaderDescription {
		display: none !important;
	}

	/* .headerColCalendar {
		font-size: x-small;
		padding-left: 2px;
	} */

}